@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';

/*
	Hyperspace by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Spotlights */

	.spotlights {
		> section {
			> .image {
				&:before {
					opacity: 0 !important;
				}
			}

			> .content {
				> .inner {
					@include vendor('transform', 'none !important');
					opacity: 1 !important;
				}
			}
		}
	}

/* Wrapper */

	.wrapper {
		> .inner {
			opacity: 1 !important;
			@include vendor('transform', 'none !important');
		}
	}

/* Sidebar */

	#sidebar {
		> .inner {
			opacity: 1 !important;
		}

		nav {
			> ul {
				> li {
					@include vendor('transform', 'none !important');
					opacity: 1 !important;
				}
			}
		}
	}